import styled from "@emotion/styled";

import { grid } from "../styles/theme";

const Main = styled.main`
  height: 100%;
  display: grid;
  grid-template-columns: repeat(${grid.desktop.cols}, 1fr);
  column-gap: ${grid.desktop.gutter};
`;

const Left = styled.div`
  height: 100%;
  grid-column: 1 / span 6;
  @media (max-width: ${grid.large.max}) {
    grid-column: 1 / span 6;
  }

  @media (max-width: ${grid.tablet.max}) {
    grid-column: 1 / span 6;
  }

  @media (max-width: 960px) {
    grid-column: 1 / span 7;
  }

  @media (max-width: 828px) {
    grid-column: 1 / span 12;
  }
`;

const Right = styled.div`
  grid-column: auto / span 6;
`;

const TwoPanel = ({ primary, secondary }) => (
  <Main>
    <Left>{primary}</Left>
    <Right>{secondary}</Right>
  </Main>
);

export default TwoPanel;
